<!--
  通用往来单位选择组件
  使用方法：
  <CommonPayeeSelect
    :code.sync="formData.payeeCode"
    :name.sync="formData.payeeName"
    :disabled="isDisabled"
    :businessUnitCode="formData.businessUnitCode"
  />
-->
<template>
  <a-row :gutter="24" :class="{ noMB: hideType }">
    <a-col :span="12" v-if="!hideType">
      <a-form-model-item :label="$t(`往来单位类型`)" required>
        <CommonSelect
          :placeholder="$t('往来单位类型')"
          :list="contactTypeList"
          :disabled="disabled"
          :code.sync="localType"
          :immediate="false"
          :allowClear="false"
          @change="changePayeeType"
        />
      </a-form-model-item>
    </a-col>
    <a-col :span="hideType ? 24 : 12" v-show="localType === 'SUPPLIER'">
      <a-form-model-item
        :label="hideType ? '' : $t(`供应商`)"
        :required="docType !== 'PaymentNote'"
      >
        <CommonQuerySelect
          api="queryPurchaseSupplierList"
          :selectOne="false"
          :params="{
            status: 1,
          }"
          :placeholder="$t(`供应商`)"
          :code.sync="localCode"
          :name.sync="localName"
          :disabled="disabled"
          valueName="supplier_code"
          labelName="supplier_name"
          @change="handleChange"
        />
      </a-form-model-item>
    </a-col>
    <a-col :span="hideType ? 24 : 12" v-show="localType === 'CUSTOMER'">
      <a-form-model-item :label="hideType ? '' : $t(`客户`)" :required="docType !== 'PaymentNote'">
        <CommonQuerySelect
          api="getCommonList"
          :selectOne="false"
          :placeholder="$t('客户')"
          :code.sync="localCode"
          :name.sync="localName"
          :params="{
            tableName: 'customer',
          }"
          :disabled="disabled"
        />
      </a-form-model-item>
    </a-col>
    <a-col :span="hideType ? 24 : 12" v-show="localType === 'STAFF'">
      <a-form-model-item :label="hideType ? '' : $t(`人员`)" :required="docType !== 'PaymentNote'">
        <CommonQuerySelect
          api="getUserList"
          :selectOne="false"
          :placeholder="$t(`人员`)"
          :code.sync="localCode"
          :name.sync="localName"
          labelName="userName"
          valueName="userCode"
          :disabled="disabled"
        />
      </a-form-model-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'CommonPayeeSelect',
  props: {
    docType: {
      type: String,
      default: undefined,
    },
    orderType: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: undefined,
    },
    code: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideType: {
      type: Boolean,
      default: false,
    },
    businessUnitCode: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      localType: this.type,
      localCode: this.code,
      localName: this.name,
    }
  },
  computed: {
    isSpecialType() {
      return !['OTHER', 'OTHER_PAYMENT'].includes(this.orderType)
    },
    contactTypeList() {
      let list = this.$store.state.contact_type
      //费用和其他类型可以选三个 其他只能选一个
      if (this.isSpecialType) {
        list = list.filter((item) => item.code === 'SUPPLIER')
      }
      return list
    },
  },
  watch: {
    type: {
      handler(newVal) {
        this.localType = newVal
      },
      immediate: true,
    },
    code: {
      handler(newVal) {
        this.localCode = newVal
      },
      immediate: true,
    },
    name: {
      handler(newVal) {
        this.localName = newVal
      },
      immediate: true,
    },
    localCode(newVal) {
      this.$emit('update:code', newVal)
    },
    localName(newVal) {
      this.$emit('update:name', newVal)
    },
    orderType() {
      if (this.isSpecialType) {
        this.$emit('update:type', 'SUPPLIER')
      }
    },
  },
  methods: {
    handleChange(item) {
      this.$emit('change', item)
    },
    changePayeeType(data) {
      this.$emit('input', data.code)
      this.$emit('update:type', data.code)
      // 清空选择的往来单位
      this.localCode = undefined
      this.localName = undefined
    },
  },
}
</script>

<style lang="less" scoped>
.common-payee-select {
  width: 100%;
}

.noMB ::v-deep .ant-form-item-control-wrapper {
  margin-bottom: 0;
}
</style>
